





import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import firebase from 'firebase'

@Component({
  components: {
  },
})
export default class SignUpView extends Vue {
}
